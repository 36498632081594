import React from 'react'
import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageBody from 'components/shared/pageBody'
import PageHeader from 'components/shared/pageHeader'
import { graphql, Link } from 'gatsby'
import Social from 'components/global/social'
import styled from 'styled-components'


const CompPageBody = styled(PageBody)`
    h1 {
        margin-bottom: 2rem;
    }
    h3 {
        margin: 1rem 0;
    }
    .col-red {
        color: var(--main);
    }
    .social {
        margin: 4rem auto 2rem auto;
        text-align: center;
    }
    section {
        h4 {
            margin-bottom: 1.5rem;
        }
        p {
            margin-bottom: 1rem;
        }
    }
`

const Competition = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  const meta = {
    title: 'Competition | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <MaxWidth $wooden>
        <CompPageBody>
          <h1>Mousetrap Memories Competition</h1>
          <h3>We want your <span className='col-red'>Mousetrap Memories!</span></h3>
          <p>Have you ever worked on The Mousetrap?<br />Have you seen it more than once?<br />Maybe you took someone special with you?</p>
            <p>Help us celebrate 70 years of The Mousetrap<br />by sending us your photos, videos and stories, and here&rsquo;s how:<br /><strong>Post &amp; tag us on our social channels</strong><br /><strong>Use #MousetrapMemories</strong></p>
        <h4>Or simply send to us via DM or email <a href="mailto:mousetrapmemories@stmartinstheatre.co.uk">Mousetrapmemories@stmartinstheatre.co.uk</a></h4>
        <div className='social'>
            <Social $comp />
        </div>
        <section>

        <h4>PRIZES</h4>
        <p>The first 1,000 entrants will be able to collect an exclusive 70th Anniversary poster from St. Martin’s Theatre. Posters will be limited to one per person. Posters will be available from 8th November 2022 at St. Martin's Theatre box office during opening hours.</p>
<p>X10 entrants will be selected at random to win a pair of tickets to our 70th anniversary performance and post-show champagne reception at the Waldorf Hotel.</p>
        </section>
        <section>
        <h4>T&Cs</h4>
        <p>1. The promoter is Mousetrap Productions Ltd whose registered office is at 11 Garrick St, London WC2E 9AU</p>
<p>2. This competition is eligible to entrants of all ages. <br />Entrants under the age of 16 must seek permission from a parent or guardian to enter.</p>
<p>3. There is no entry fee and no ticket purchase necessary to enter this competition.</p>
<p>4. By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.</p>
<p>5. The competition will run from 4pm on Thursday 13th October 2022 via the official Mousetrap <a href="https://www.facebook.com/MousetrapLondon/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://twitter.com/MousetrapLondon" target="_blank" rel="noopener noreferrer">Twitter</a> and <a href="https://www.instagram.com/mousetraplondon/" target="_blank" rel="noopener noreferrer"> Instagram </a> channels.</p>
<p>6. The competition will close at 5pm on Monday 7th November 2022. No responsibility can be accepted for entries not received for whatever reason.</p>
<p>7. The winners will be chosen at random and notified no later than 6pm on Thursday 10th November 2022. <br />10x winners will receive a pair of tickets to the 70th Anniversary performance on Friday 25th November at 12:00PM, followed by a champagne reception at the Waldorf Hotel, Covent Garden.<br />The first 1,000 entrants will be able to collect an exclusive 70th Anniversary poster from St. Martin&rsquo;s Theatre. Posters will be limited to one per person.</p>
<p>8. In order to enter the competition, entrants must post to Facebook, Twitter or Instagram, OR direct message or email their stories, photos &amp; videos, OR submit via email to <a href='mailto:Mousetrapmemories@stmartinstheatre.co.uk'>Mousetrapmemories@stmartinstheatre.co.uk</a></p>
<p>9. Children and young people may use a parent or guardian&rsquo;s social profile to enter.</p>
<p>10. All entries on Twitter must tag @mousetraplondon and Instagram must tag @themousetraplondon and must use the hashtag #MousetrapMemories to be eligible for this prize. <br /><br /></p>
<p>12. Valid entries could include, but are not limited to:<br />A photo of the entrant at the theatre visiting or working<br />A short video message on the entrants Mousetrap Memory<br />Written words</p>
<p>13. Entrants may share as many photos and videos as they like.</p>
<p>14. By submitting photos, videos and written accounts, entrants grant the production permission to use those photos, videos and written accounts to further promote The Mousetrap, globally and in perpetuity. This includes, but is not limited to, posting the photos, videos and written accounts to the official Mousetrap social platforms and hosting the photos, videos and written accounts on the Mousetrap website <a href="https://uk.the-mousetrap.co.uk/" target="_blank" rel="noopener noreferrer">(https://uk.the-mousetrap.co.uk/)</a></p>
<p>15. As many entries as possible will be included in a final compilation video.</p>
<p>16. Failure to respond to notification of winning within 3 days of being contacted forfeits the prize and the promoter will have the option to randomly pick another winner from the pool of entrants.</p>
<p>17. There is no cash or other alternative to the prize stated and the prize is not transferable and no part or parts of the prize may be substituted for other benefits, items or additions. The promoter cannot guarantee the appearance of any one particular cast member on Friday 25th November 2022 due to circumstances beyond their control, such as cast holidays or illness. </p>
<p>18. The promoter&rsquo;s decision is final and binding on the entrants - no correspondence will be entered into. <br />19. The promoter reserves the right to cancel or amend the competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual or anticipated breach of any applicable law or regulation or any other event outside of the promoter&rsquo;s control. Any changes to the competition will be notified to entrants as soon as possible by the promoter. </p>
<p>20. The winners&rsquo; names, email addresses and telephone contact details will be requested for prize fulfilment. Any personal data relating to the winner or any other entrants will be used solely in accordance with current [UK] data protection legislation and will not be disclosed to a third party without the entrant&rsquo;s prior consent. </p>
<p>21. This promotion is in no way sponsored, endorsed or administered by, or associated with, Facebook, Twitter, Instagram or any other Social Network. You are providing your information to The Mousetrap and not to any other party.</p>
<p><span className='col-red'>Please note the recommended age for the Mousetrap is 7 years and above. Children under the ages of 16 will not be permitted entry into the auditorium without and adult present.</span></p>
        </section>
        </CompPageBody>
      </MaxWidth>
    </Layout>
  )
}

export default Competition

export const CompetitionQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "about2-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "about2-deslktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
